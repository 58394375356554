<script setup lang="ts">
import { computed, h, ref, type VNode, watch, inject, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { helpers, maxValue, minValue } from '@vuelidate/validators';
import useVuelidate, { type ValidationArgs } from '@vuelidate/core';
import { useQuery } from '@vue/apollo-composable';
import sortBy from 'lodash/sortBy';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import sum from 'lodash/sum';
import dayjs from '@/lib/dayjs/config';
import {
  DataPointTypeValueUnitEnum,
  type MlQuestionInputQuery,
  type MlQuestionInputQueryVariables,
  ValueDataTypeEnum,
  type PgExternalDataEntryQuery,
  type MlQuestionFormQuery,
} from '@/__generated__/types';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import MlRadio from '@/components/molecules/MlRadio.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import MlDatePicker from '@/components/molecules/MlDatePicker.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgS3FilePicker from '@/components/molecules/MlFilePicker/OgS3FilePicker.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import COUNTRIES from '@/constants/countries';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import SplitInput from '@/components/atoms/SplitInput.vue';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import EmissionSplitInput from '@/components/atoms/EmissionSplitInput.vue';
import type {
  TDataPointRequest,
  TDataPointTypeOverride,
  DataPointRequestWithValueSourceNames,
} from '../../../types';
import ML_QUESTION_INPUT_QUERY from './MlQuestionInput.query';

const props = defineProps<{
  modelValue?: unknown;
  file?: TPartialRepositoryFile | null;
  dataPointRequest:
    | TDataPointRequest
    | PgExternalDataEntryQuery['getDelegatedDataPointRequests'][number]; // needed to for external data entry
  valueUnit?: DataPointTypeValueUnitEnum;
  valueUnitDivisor?: DataPointTypeValueUnitEnum;
  override?: TDataPointTypeOverride;
  overrideInputType: ValueDataTypeEnum;
  dataPointRequestsWithValueSourceNames: DataPointRequestWithValueSourceNames[];
  suggestionFormular?: MlQuestionFormQuery['getDataPointRequest'];
  options?: {
    onEnter?: () => void;
  };
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: unknown): void;
  (e: 'update:file', value?: TPartialRepositoryFile | null): void;
}>();

const { t, locale } = useI18n();
const translateCategory = useCategoryTranslate();
const { formatNumber } = useFormatNumber();
const isExternal = inject(
  'isExternal',
  computed(() => false),
);
const canToggleQuestions = inject('canToggleQuestions', ref(true));

const dataPointType = computed(() => props.dataPointRequest.dataPointType);

const booleanOptions = [
  { name: 'yes', label: 'Yes', value: 'yes' },
  { name: 'no', label: 'No', value: 'no' },
];

const countryOptions = COUNTRIES.reduce<Record<string, string>>(
  (newCountries, country) => {
    newCountries[country['alpha-2']] = country.name;
    return newCountries;
  },
  {},
);

const recentlyApprovedQueryVariables = computed(() => ({
  dataPointTypeIds: [props.dataPointRequest.dataPointType._id],
  locationId: props.dataPointRequest.location._id,
}));
let recentlyApprovedResults: Ref<MlQuestionInputQuery | undefined>;
if (!isExternal.value) {
  const res = useQuery<MlQuestionInputQuery, MlQuestionInputQueryVariables>(
    ML_QUESTION_INPUT_QUERY,
    recentlyApprovedQueryVariables,
    { fetchPolicy: 'network-only' },
  );
  recentlyApprovedResults = res.result;
}

const recentlyApprovedDatapoints = computed(() =>
  sortBy(
    Array.from(
      recentlyApprovedResults?.value?.getDataPointsByTypeAndLocation ?? [],
    ),
    (item: MlQuestionInputQuery['getDataPointsByTypeAndLocation'][number]) =>
      item.to,
  )
    .reverse()
    .slice(0, 4),
);

const previousDatapoint =
  ref<MlQuestionInputQuery['getDataPointsByTypeAndLocation'][0]>();

const previousDataPointRequestWithValueSource = computed(() => {
  const dataPointRequests = props.dataPointRequestsWithValueSourceNames;
  const index = dataPointRequests.findIndex(
    (dpr) =>
      dpr.id === props.dataPointRequest._id &&
      dpr.fromDate === props.dataPointRequest.from,
  );

  if (index === -1 || index === 0) {
    return null;
  }

  return dataPointRequests[index - 1];
});

watch(recentlyApprovedDatapoints, () => {
  // eslint-disable-next-line prefer-destructuring
  const currentTime = new Date().getTime();
  // calculate the closest datapoint (in the future or past)
  previousDatapoint.value = recentlyApprovedDatapoints.value
    .filter(
      (recentlyApproved) =>
        recentlyApproved.from !== props.dataPointRequest.from ||
        recentlyApproved.to !== props.dataPointRequest.to,
    )
    .reduce((closest: typeof previousDatapoint.value | undefined, curr) => {
      if (!closest) return curr;

      if (
        Math.abs(currentTime - new Date(curr.to).getTime()) <
        Math.abs(currentTime - new Date(closest.to).getTime())
      ) {
        return curr;
      }

      return closest;
    }, undefined);
});

const comparedToLastValue = (
  value: number | Record<string, unknown>[],
  type: 1 | 2,
) => {
  if (value === null) return true;

  const previousValue = Number(previousDatapoint.value?.value);
  if (
    Number.isNaN(previousValue) ||
    ![ValueDataTypeEnum.NUMERIC, ValueDataTypeEnum.NUMERIC_SPLIT].includes(
      props.dataPointRequest.dataPointType.valueDataType,
    )
  )
    return true;

  if (typeof value === 'number') {
    // eslint-disable-next-line default-case
    switch (type) {
      case 1:
        return value <= previousValue * 1.1;
      case 2:
        return value >= previousValue * 0.9;
    }
  } else {
    // eslint-disable-next-line default-case
    switch (type) {
      case 1:
        return (
          sum(value.map((val) => Number(val.value))) <= previousValue * 1.1
        );
      case 2:
        return (
          sum(value.map((val) => Number(val.value))) >= previousValue * 0.9
        );
    }
  }
};

const lessThan100 = (value: number | Record<string, unknown>[]) => {
  if (value === null) return true;

  if (typeof value === 'number') {
    return value <= 100;
  }

  return value.map((val) => Number(val.value)).every((val) => val <= 100);
};

const validators = computed<Partial<Record<ValueDataTypeEnum, ValidationArgs>>>(
  () => ({
    [ValueDataTypeEnum.NUMERIC]: {
      minValue: helpers.withMessage(
        t('Please input correct value'),
        minValue(0),
      ),
      ...(props.valueUnit === DataPointTypeValueUnitEnum.PERCENT
        ? {
            maxValue: helpers.withMessage(
              t("Percentage can't be greater than 100"),
              maxValue(100),
            ),
          }
        : {}),
      tooLow: helpers.withMessage(
        t('This appears to be lower than the most recent data.'),
        helpers.withAsync((number: number) => comparedToLastValue(number, 2)),
      ),
      tooHigh: helpers.withMessage(
        t('This appears to be higher than the most recent data.'),
        helpers.withAsync((number: number) => comparedToLastValue(number, 1)),
      ),
    },
    [ValueDataTypeEnum.NUMERIC_SPLIT]: {
      ...(props.valueUnit === DataPointTypeValueUnitEnum.PERCENT
        ? {
            maxValue: helpers.withMessage(
              t("Percentage can't be greater than 100"),
              lessThan100,
            ),
          }
        : {}),
      tooLow: helpers.withMessage(
        t('This appears to be lower than the most recent data.'),
        helpers.withAsync((number: number | Record<string, unknown>[]) =>
          comparedToLastValue(number, 2),
        ),
      ),
      tooHigh: helpers.withMessage(
        t('This appears to be higher than the most recent data.'),
        helpers.withAsync((number: number | Record<string, unknown>[]) =>
          comparedToLastValue(number, 1),
        ),
      ),
    },
  }),
);

const isFileInput = (value: unknown): value is TPartialRepositoryFile => {
  return dataPointType.value.valueDataType === ValueDataTypeEnum.UPLOAD;
};
const isMultiselect = (value: unknown): value is string[] => {
  return (
    dataPointType.value.valueDataType === ValueDataTypeEnum.MULTIPLE_CHOICE
  );
};

const modelValueDirty = ref(false);

const modelValueProxy = computed({
  get: () => (isFileInput(props.file) ? props.file : props.modelValue),
  set: (value) => {
    modelValueDirty.value = true;
    const isModelValueArray =
      Array.isArray(props.modelValue) && Array.isArray(value);

    if (isFileInput(value)) {
      return emit('update:file', value);
    }

    if (isMultiselect(value) && isModelValueArray) {
      const isNoneSelected =
        !props.modelValue.includes('none') && value.includes('none');
      const optionsWithoutNone = value.filter((option) => option !== 'none');
      emit('update:modelValue', isNoneSelected ? ['none'] : optionsWithoutNone);
    } else {
      emit('update:modelValue', value);
    }
  },
});

const v = useVuelidate(
  { modelValue: validators.value[props.overrideInputType] ?? {} },
  { modelValue: modelValueProxy },
);

const textCopied = inject('textCopied', ref(''));

const inputs = computed<Record<ValueDataTypeEnum, VNode>>(() => ({
  [ValueDataTypeEnum.TEXT]: h(AtInput, {
    unit: props.valueUnit,
    unitDivisor: props.valueUnitDivisor,
    onEnter: props.options?.onEnter,
    initialContent: textCopied.value,
  }),
  [ValueDataTypeEnum.TEXT_LONG]: h(MlTextarea, {
    wrapperClass: '!w-full',
    class: '!w-full',
    dataPointRequest: props.dataPointRequest,
    initialContent: textCopied.value,
  }),
  [ValueDataTypeEnum.TEXT_SPLIT]: h(SplitInput, {
    valueSource: props.dataPointRequest.valueSource,
    categoryNames:
      previousDataPointRequestWithValueSource.value?.valueSourceNames ?? [],
    component: h(AtInput, {
      type: 'text',
      unit: props.valueUnit,
      unitDivisor: props.valueUnitDivisor,
    }),
    dataPointRequestId: props.dataPointRequest._id,
  }),
  [ValueDataTypeEnum.DATE]: h(MlDatePicker),
  [ValueDataTypeEnum.DATE_RANGE]: h(MlDatePicker, { range: true }),
  [ValueDataTypeEnum.NUMERIC]: h(AtInput, {
    type: 'number',
    min: 0,
    unit: props.valueUnit,
    unitDivisor: props.valueUnitDivisor,
    onEnter: props.options?.onEnter,
  }),
  [ValueDataTypeEnum.NUMERIC_SPLIT]: h(SplitInput, {
    valueSource: props.dataPointRequest.valueSource,
    categoryNames:
      previousDataPointRequestWithValueSource.value?.valueSourceNames ?? [],
    component: h(AtInput, {
      type: 'number',
      min: 0,
      unit: props.valueUnit,
      unitDivisor: props.valueUnitDivisor,
    }),
    dataPointRequestId: props.dataPointRequest._id,
  }),
  [ValueDataTypeEnum.EMISSIONS_SPLIT]: h(EmissionSplitInput, {
    valueSource: props.dataPointRequest.valueSource,
    emissionSubcategory:
      props.dataPointRequest.dataPointType.emissionSubcategory,
    component: h(AtInput, {
      type: 'number',
      min: 0,
      unit: props.valueUnit,
      unitDivisor: props.valueUnitDivisor,
    }),
    dataPointRequestId: props.dataPointRequest._id,
    dataPointRequestYear: new Date(props.dataPointRequest.from).getFullYear(),
  }),
  [ValueDataTypeEnum.COUNTRY]: h(MlAutocomplete, {
    options: countryOptions,
    sortedOptions: true,
    type: 'select',
  }),
  [ValueDataTypeEnum.MULTIPLE_COUNTRIES]: h(MlAutocomplete, {
    options: countryOptions,
    multiple: true,
    type: 'select',
  }),
  [ValueDataTypeEnum.CHOICE]: h(MlSelect, {
    variant: 'inline',
    options: dataPointType.value.valueDataTypeOptions,
    sortedOptions: true,
  }),
  [ValueDataTypeEnum.MULTIPLE_CHOICE]: h(MlAutocomplete, {
    options: dataPointType.value.valueDataTypeOptions,
    multiple: true,
    type: 'select',
    sortedOptions: true,
  }),
  [ValueDataTypeEnum.BOOLEAN]: h(MlRadio, { options: booleanOptions }),
  [ValueDataTypeEnum.BOOLEAN_UNSURE]: h(MlRadio, {
    options: [
      ...booleanOptions,
      { name: 'not sure', label: 'Not sure', value: 'not sure' },
    ],
  }),
  [ValueDataTypeEnum.USER_ASSIGNATION]: h(AtInput, {
    type: 'email',
    autocomplete: 'address-level1',
    placeholder: t("Colleague's Email"),
  }),
  [ValueDataTypeEnum.UPLOAD]: h(OgS3FilePicker, {
    compact: true,
    showRecentFiles: true,
    accept: '.pdf',
    description: t('{fileTypes} up to {maxSizeInMB} MB.', {
      fileTypes: 'PDF',
      maxSizeInMB: 10,
    }),
  }),
}));

const emissionFactor = computed(() => {
  const dprYear = parseInt(
    dayjs
      .utc(props.dataPointRequest.from || new Date())
      .startOf('day')
      .format('YYYY'),
    10,
  );
  if (
    props.override?.emissionFactors?.find(
      (_emissionFactor) => _emissionFactor.year === dprYear,
    )
  ) {
    return props.override?.emissionFactors.find(
      (_emissionFactor) => _emissionFactor.year === dprYear,
    );
  }
  return (
    dataPointType.value.emissionFactors.find(
      (_emissionFactor) => _emissionFactor.year === dprYear,
    ) ?? null
  );
});

const suggestionFormularInfo = computed<string>(() => {
  if (
    props.suggestionFormular?.dataPointType.suggestionFormular &&
    props.suggestionFormular?.suggestionFormularResult &&
    props.suggestionFormular.suggestionFormularResult.dependencies?.length
  ) {
    const formatter = new Intl.ListFormat(locale.value, {
      style: 'long',
      type: 'conjunction',
    });

    const variables = {
      subcategory: formatter.format([
        ...new Set(
          props.suggestionFormular.suggestionFormularResult.dependencies
            ?.toSorted(
              (a, b) =>
                a.activeReportingFramework.order -
                b.activeReportingFramework.order,
            )
            .filter(
              (dependency) => dependency.activeReportingFramework.category,
            )
            .map((dependency) =>
              translateCategory(
                dependency.activeReportingFramework.category ?? '',
                dependency.activeReportingFramework.subcategory ?? '',
              ),
            ),
        ),
      ]),
    };

    if (
      Math.abs(
        props.modelValue -
          props.suggestionFormular.suggestionFormularResult.result,
      ) > 0.1
    ) {
      return t(
        'This data does not correspond to the same data request that was entered in "{subcategory}". Click to re-apply.',
        variables,
      );
    }

    if (props.suggestionFormular.suggestionFormularResult.dependencies.length) {
      return t(
        'This value has been entered based on the calculation of same data in "{subcategory}".',
        variables,
      );
    }

    return t(
      'This value has been entered based on the input for the same data in "{subcategory}".',
      variables,
    );
  }

  return '';
});

const reapplySuggestion = () => {
  modelValueProxy.value =
    props.suggestionFormular.suggestionFormularResult.result;
};
</script>

<template>
  <div>
    <slot />
    <p
      v-if="
        previousDatapoint &&
        [
          ValueDataTypeEnum.NUMERIC,
          ValueDataTypeEnum.BOOLEAN,
          ValueDataTypeEnum.BOOLEAN_UNSURE,
        ].includes(previousDatapoint.dataPointType.valueDataType) &&
        previousDatapoint.to !== props.dataPointRequest.to
      "
      class="text-xs text-gray-400"
    >
      {{ t('Last reported:') }}
      <MlDataPointValue
        :data-point-value-and-type="previousDatapoint"
        :inline="true"
      />
      {{ t('in') }}
      <AtDataPointRequestDate
        :from="previousDatapoint.from"
        :to="previousDatapoint.to"
      />
    </p>
    <div
      v-if="
        !canToggleQuestions &&
        props.dataPointRequest.dataPointType.valueDataType ===
          ValueDataTypeEnum.MULTIPLE_CHOICE
      "
      class="absolute h-full w-full top-0 left-0 bg-transparent"
      @click.stop="canToggleQuestions = true"
    />
    <component
      :is="inputs[overrideInputType]"
      v-model="modelValueProxy"
      class="w-64"
      :errors="v.modelValue.$silentErrors"
      required
      :data-cy="`MlQuestionInput-${dataPointType.valueDataType}`"
      v-bind="$attrs"
      :previousDatapoint="previousDatapoint"
      :disabled="props.disabled"
    />
    <p
      v-if="
        [ValueDataTypeEnum.NUMERIC, ValueDataTypeEnum.NUMERIC_SPLIT].includes(
          dataPointType.valueDataType,
        ) &&
        recentlyApprovedDatapoints &&
        !recentlyApprovedDatapoints.find(
          (dp) => props.dataPointRequest._id === dp.dataPointRequest?._id,
        ) &&
        recentlyApprovedDatapoints.length &&
        v.modelValue.$silentErrors.length === 0 &&
        modelValueProxy !== null &&
        modelValueDirty
      "
      class="mt-1 text-xs text-success"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ t('Great! This value appears to be expected.') }}
      </span>
    </p>
    <p
      v-if="suggestionFormularInfo"
      class="mt-1 text-xs cursor-pointer"
      @click="reapplySuggestion"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ suggestionFormularInfo }}
      </span>
    </p>

    <p v-if="emissionFactor" class="text-xs text-gray-400">
      {{ t('Emission factor:') }}
      {{ formatNumber(emissionFactor.factor, 2, 10) }} (tCO2eq/x)
    </p>

    <AtInfoBox
      v-if="dataPointType.valueDataType === ValueDataTypeEnum.TEXT_LONG"
      class="mt-1.5"
      :content="
        t(
          'Note: Your text answer will be displayed in the language in which it is written.',
        )
      "
    />
  </div>
</template>
