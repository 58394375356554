import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  DeleteRepositoryFileMutation,
  DeleteRepositoryFileMutationVariables,
} from '@/__generated__/types';

export const DELETE_REPOSITORY_FILE = gql`
  mutation DeleteRepositoryFile($repositoryFileId: String!) {
    deleteRepositoryFile(repositoryFileId: $repositoryFileId) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  DeleteRepositoryFileMutation,
  DeleteRepositoryFileMutationVariables
>;

const useDeleteRepositoryFile = (options?: TOptions) =>
  useMutation<
    DeleteRepositoryFileMutation,
    DeleteRepositoryFileMutationVariables
  >(DELETE_REPOSITORY_FILE, options);

export default useDeleteRepositoryFile;
