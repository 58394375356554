import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import { v4 as uuidv4 } from 'uuid';
import dayjs from '@/lib/dayjs/config';
import { DataPointTypeEmissionTypeEnum } from '@/__generated__/types';
import type {
  DataPoint,
  DataPointType,
  PreparedCarbonFootprintDataPoint,
  PreparedCarbonFootprintTotal,
  PreparedPerYear,
  PreparedPerYearProjectItem,
  PreparedPerYearScopeItem,
  PreparedPerYearScopeSublevelItem,
  PreparedPerYearSourceItem,
  Project,
  SublevelItem,
} from '../types';
import { getProjectsTrees, type ProjectsTree } from './projectsTree';
import { getDataPointsProjectsTrees } from './dataPointsProjectsTrees';
import { calculate, summarize } from './summarize';

type Years = PreparedPerYear['years'];

const { SCOPE_2_LOCATION_BASED, SCOPE_2_MARKET_BASED } =
  DataPointTypeEmissionTypeEnum;

const scopeOrder: keyof DataPointTypeEmissionTypeEnum = {
  SCOPE_1: 1,
  SCOPE_2_LOCATION_BASED: 2,
  SCOPE_2_MARKET_BASED: 2,
  SCOPE_2: 2,
  SCOPE_3: 3,
  'OUTSIDE': 99,
}

function prepareProjectItem(
  project: SublevelItem,
  years: Years,
  dataPointType: DataPointType,
): PreparedPerYearProjectItem {
  let dataPoints: PreparedPerYearProjectItem['dataPoints'];
  if (project.dataPoints.length > 0) {
    dataPoints = mapValues(
      groupBy(project.dataPoints, (dataPoint) =>
        dayjs(dataPoint.from).year().toString(),
      ),
      (yearDataPoints) => {
        let sum = 0;
        const hasSumDataPoint =
          yearDataPoints.some((dp) => dp.dataPointType.emissionSubcategory) &&
          yearDataPoints.some((dp) => !dp.dataPointType.emissionSubcategory);

        if (hasSumDataPoint) {
          sum = yearDataPoints
            .filter((dp) => !dp.dataPointType.emissionSubcategory)
            .reduce((acc, curr) => acc + curr.value, 0);
        } else {
          sum = yearDataPoints.reduce(
            (acc, dataPoint) => acc + dataPoint.value,
            0,
          );
        }

        const count = yearDataPoints.length;

        return {
          dataPointType,
          value: calculate(sum, count, dataPointType.summarizingMethod!),
          calculation: {
            sum,
            count,
          },
        } as PreparedCarbonFootprintDataPoint;
      },
    );
  } else {
    dataPoints = null;
  }

  let sublevels: PreparedPerYearProjectItem['sublevels'];
  if (project.sublevels && project.sublevels.length > 0) {
    sublevels = project.sublevels.map((sublevel) => {
      return prepareProjectItem(sublevel, years, dataPointType);
    });
  } else {
    sublevels = null;
  }

  let total: PreparedPerYearProjectItem['total'];
  if (sublevels) {
    total = years.reduce<PreparedCarbonFootprintTotal>((values, year) => {
      const [sum, count] = summarize(
        sublevels as PreparedPerYearProjectItem[],
        year.key,
      );

      return {
        ...values,
        [year.key]: {
          dataPointType,
          value: calculate(sum, count, dataPointType.summarizingMethod!),
          calculation: {
            sum,
            count,
          },
        },
      };
    }, {});
  } else {
    total = null;
  }

  return {
    id: project.id,
    name: project.name,
    dataPoints,
    total,
    sublevels,
  };
}

function prepareSourceItem(
  dataPoints: DataPoint[],
  projectsTrees: ProjectsTree[],
  years: Years,
  locationAsProject: boolean,
): PreparedPerYearSourceItem {
  return {
    name: dataPoints[0].dataPointType.emissionSubcategory!,
    projects: getDataPointsProjectsTrees(
      projectsTrees,
      dataPoints,
      locationAsProject,
    ).map((project) =>
      prepareProjectItem(project, years, dataPoints[0].dataPointType),
    ),
  };
}

function prepareScopeSublevel(
  dataPoints: DataPoint[],
  projectsTrees: ProjectsTree[],
  years: Years,
  locationAsProject: boolean,
): PreparedPerYearScopeSublevelItem {
  let sources = Object.values(
    groupBy(
      dataPoints,
      (dataPoint) => dataPoint.dataPointType.emissionSubcategory,
    ),
  )
    // Filter out data points with invalid emission subcategory.
    .filter(
      (emissionSubcategoryDataPoints) =>
        emissionSubcategoryDataPoints[0].dataPointType.emissionSubcategory,
    )
    .map((emissionSubcategoryDataPoints) => {
      return prepareSourceItem(
        emissionSubcategoryDataPoints,
        projectsTrees,
        years,
        locationAsProject,
      );
    });
  sources = orderBy(sources, [(source) => source.name]);

  const totalPerProject: PreparedPerYearScopeSublevelItem['totalPerProject'] =
    prepareSourceItem(
      dataPoints,
      projectsTrees,
      years,
      locationAsProject,
    ).projects;

  const { dataPointType } = dataPoints[0];
  const total: PreparedPerYearScopeSublevelItem['total'] = years.reduce(
    (values, year) => {
      let sum = 0;
      let count = 0;
      sources.forEach((source) => {
        source.projects.forEach((project) => {
          if (project.total && project.total[year.key]) {
            sum += project.total[year.key].calculation.sum;
            count += project.total[year.key].calculation.count;
          } else if (project.dataPoints && project.dataPoints[year.key]) {
            sum += project.dataPoints[year.key].calculation.sum;
            count += project.dataPoints[year.key].calculation.count;
          }
        });
      });

      return {
        ...values,
        [year.key]: {
          dataPointType,
          value: calculate(sum, count, dataPointType.summarizingMethod!),
          calculation: {
            sum,
            count,
          },
        },
      };
    },
    {},
  );

  return {
    name: dataPoints[0].dataPointType.emissionCategory!,
    sources,
    totalPerProject,
    total,
  };
}

function prepareScope(
  dataPoints: DataPoint[],
  projectsTrees: ProjectsTree[],
  years: Years,
  locationAsProject: boolean,
): PreparedPerYearScopeItem {
  let sublevels = Object.values(
    groupBy(
      dataPoints,
      (dataPoint) => dataPoint.dataPointType.emissionCategory,
    ),
  )
    // Filter out data points with invalid emission category.
    .filter(
      (emissionCategoryDataPoints) =>
        emissionCategoryDataPoints[0].dataPointType.emissionCategory,
    )
    .map((emissionCategoryDataPoints) => {
      return prepareScopeSublevel(
        emissionCategoryDataPoints,
        projectsTrees,
        years,
        locationAsProject,
      );
    });
  sublevels = orderBy(sublevels, [(sublevel) => sublevel.name]);

  const totalPerProject: PreparedPerYearScopeItem['totalPerProject'] =
    prepareSourceItem(
      dataPoints,
      projectsTrees,
      years,
      locationAsProject,
    ).projects;

  const { dataPointType } = dataPoints[0];
  const total: PreparedPerYearScopeItem['total'] = years.reduce(
    (values, year) => {
      let sum = 0;
      let count = 0;
      sublevels.forEach((sublevel) => {
        if (sublevel.total && sublevel.total[year.key]) {
          sum += sublevel.total[year.key].calculation.sum;
          count += sublevel.total[year.key].calculation.count;
        }
      });

      return {
        ...values,
        [year.key]: {
          dataPointType,
          value: calculate(sum, count, dataPointType.summarizingMethod!),
          calculation: {
            sum,
            count,
          },
        },
      };
    },
    {},
  );

  return {
    scope: dataPoints[0].dataPointType.emissionType!,
    sublevels,
    total,
    totalPerProject,
  };
}

export function formatScope2DataPoints( // !!! if you modify this method: do not forget to amend the backend version too
  allDataPoints: DataPoint[],
  scope2EmissionTypeExcluded:
    | typeof SCOPE_2_LOCATION_BASED
    | typeof SCOPE_2_MARKET_BASED,
) {
  const rawDataPoints = allDataPoints
    .map((dp) => ({
      ...dp,
      dataPointType: {
        ...dp.dataPointType,
        // eslint-disable-next-line no-nested-ternary
        emissionType: (dp.dataPointType.emissionType ===
        DataPointTypeEmissionTypeEnum.SCOPE_2
          ? dp.dataPointType.emissionSubcategory?.includes('MARKET-BASED')
            ? SCOPE_2_MARKET_BASED
            : SCOPE_2_LOCATION_BASED
          : dp.dataPointType.emissionType) as DataPointTypeEmissionTypeEnum,
      },
    }))
    .reduce(
      (acc, curr) => {
        const currentDataPointEmissionSubcategoryName =
          curr.dataPointType.emissionSubcategory?.split('LOCATION-BASED')[0] ??
          '';
        if (
          // location-based dp exists but market-based parallel dp doesn't exist
          curr.dataPointType.emissionType === SCOPE_2_LOCATION_BASED &&
          allDataPoints.filter(
            (dp) =>
              dp.location?._id === curr.location?._id && // backend uses .equals()
              dp.dataPointType.emissionSubcategory?.startsWith(
                currentDataPointEmissionSubcategoryName,
              ),
          ).length <= 1
        ) {
          // duplicate location-based dp in market-based category
          return [
            ...acc,
            curr,
            {
              ...curr,
              _id: uuidv4(), // backend uses ObjectId
              dataPointType: {
                ...curr.dataPointType,
                emissionType: SCOPE_2_MARKET_BASED,
              },
            },
          ];
        }
        return [...acc, curr];
      },
      [] as (DataPoint & {
        dataPointType: { emissionType: DataPointTypeEmissionTypeEnum };
      })[],
    )
    .filter(({ dataPointType }) => {
      return (
        dataPointType.emissionCategory &&
        dataPointType.emissionSubcategory &&
        dataPointType.valueUnit
      );
    });

  const hasLocationBasedDataPoint = !!rawDataPoints.find(
    (rawDp) => rawDp.dataPointType.emissionType === SCOPE_2_LOCATION_BASED,
  );
  const hasMarketBasedDataPoint = !!rawDataPoints.find(
    (rawDp) => rawDp.dataPointType.emissionType === SCOPE_2_MARKET_BASED,
  );

  const hasOnlyMarketOrLocationBased =
    (scope2EmissionTypeExcluded === SCOPE_2_MARKET_BASED &&
      hasMarketBasedDataPoint &&
      !hasLocationBasedDataPoint) ||
    (scope2EmissionTypeExcluded === SCOPE_2_LOCATION_BASED &&
      hasLocationBasedDataPoint &&
      !hasMarketBasedDataPoint);

  const filteredDataPoints = hasOnlyMarketOrLocationBased
    ? rawDataPoints
    : rawDataPoints.filter(
        (dp) =>
          scope2EmissionTypeExcluded !==
          (dp.dataPointType.emissionType as DataPointTypeEmissionTypeEnum),
      );

  return filteredDataPoints;
}

export function getPerYear(
  allDataPoints: DataPoint[],
  projects: Project[],
  locationAsProject: boolean,
  scope2EmissionTypeExcluded:
    | typeof SCOPE_2_LOCATION_BASED
    | typeof SCOPE_2_MARKET_BASED,
): PreparedPerYear {
  const result: PreparedPerYear = {
    years: [],
    scopes: [],
    total: {},
  };

  const scope2DataPointsFormatted = formatScope2DataPoints(
    allDataPoints.filter(
      (dp) =>
        dp.dataPointType.emissionType === DataPointTypeEmissionTypeEnum.SCOPE_2,
    ),
    scope2EmissionTypeExcluded as
      | typeof SCOPE_2_LOCATION_BASED
      | typeof SCOPE_2_MARKET_BASED,
  );

  const dataPoints = [
    ...allDataPoints.filter(
      (dp) =>
        dp.dataPointType.emissionType !== DataPointTypeEmissionTypeEnum.SCOPE_2,
    ),
    ...scope2DataPointsFormatted,
  ];

  const projectsTrees = getProjectsTrees(projects);

  result.years = [
    ...new Set(dataPoints.map((dataPoint) => dayjs(dataPoint.from).year())),
  ]
    .sort((dateA, dateB) => (dateA > dateB ? -1 : 1))
    .map((year) => ({
      key: year.toString(),
      year: year.toString(),
    }));

  result.scopes = Object.values(
    groupBy(dataPoints, (dataPoint) => dataPoint.dataPointType.emissionType),
  ).map((scopeDataPoints) => {
    return prepareScope(
      scopeDataPoints,
      projectsTrees,
      result.years,
      locationAsProject,
    );
  });

  result.scopes = orderBy(result.scopes, [(scope) => scopeOrder[scope.scope]]);

  const { dataPointType } = dataPoints[0];
  result.total = result.years.reduce((values, year) => {
    let sum = 0;
    let count = 0;
    result.scopes.forEach((scope) => {
      if (scope.scope === 'OUTSIDE') {
        return;
      }

      if (scope.total && scope.total[year.key]) {
        sum += scope.total[year.key].calculation.sum;
        count += scope.total[year.key].calculation.count;
      }
    });

    return {
      ...values,
      [year.key]: {
        dataPointType,
        value: calculate(sum, count, dataPointType.summarizingMethod!),
        calculation: {
          sum,
          count,
        },
      },
    };
  }, {});

  return result;
}
