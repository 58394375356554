<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import type { ApolloError } from '@apollo/client/core';
import MlFilePicker from '@/components/molecules/MlFilePicker/MlFilePicker.vue';
import useUploadFileMutation from '@/api/mutations/RepositoryFile/uploadFile.mutation';
import type {
  UploadFileMutation,
  PgDataEntryUploadQuery,
} from '@/__generated__/types';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import AtLoader from '../atoms/AtLoader/AtLoader.vue';
import PG_DATA_ENTRY_UPLOAD_QUERY from './PgDataEntryUpload.query';

const { t } = useI18n();

const { result } = useQuery<PgDataEntryUploadQuery>(PG_DATA_ENTRY_UPLOAD_QUERY);

const { mutate: uploadFileMutation, loading: updloadFileLoading } =
  useUploadFileMutation();

const importedDataPoints = ref<
  UploadFileMutation['uploadCsvForHistoricImport']
>([]);
const errors = ref<ApolloError>();

const selectedFile = ref();
const handleFileSelected = async ([file]: File[]) => {
  if (!file) return;

  if (!selectedLocation.value) {
    // eslint-disable-next-line no-console
    console.log('Unable to proceed: No project selected.');
    return;
  }

  try {
    const dp = await uploadFileMutation({
      csvContent: await file.text(),
      locationId: selectedLocation.value,
    });

    if (dp?.data) {
      importedDataPoints.value.push(...dp.data.uploadCsvForHistoricImport);
      errors.value = undefined;
    }
  } catch (e) {
    errors.value = e as ApolloError;
  }
};

const locationOptions = computed(
  () =>
    result.value?.entityLocationSummaries.reduce<Record<string, string>>(
      (acc, { _id, name }) => ({ ...acc, [_id]: name }),
      {},
    ) ?? {},
);
const selectedLocation = ref<string>();

watch(
  locationOptions,
  () => {
    [selectedLocation.value] = Object.keys(locationOptions.value);
  },
  { immediate: true },
);
</script>

<template>
  <div class="pt-5 px-6 flex flex-col lg:mt-24">
    <div class="flex-1">
      <!-- Page title & actions -->
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{ t('Data Entry: CSV upload (internal)') }}
      </h1>

      <div class="mb-5">
        <p class="my-5">
          {{
            t(
              'Before uploading a file, please make sure to select a default project. Projects provided in the CSV will override this.',
            )
          }}
        </p>

        <MlSelect
          v-model="selectedLocation"
          wrapperClass="w-64"
          title=""
          data-cy="MlSelectLocation"
          :options="locationOptions"
        />
      </div>

      <div>
        <MlFilePicker
          v-model="selectedFile"
          accept=".csv"
          data-cy="MlFilePicker"
          class="mb-5"
          @update:modelValue="handleFileSelected"
        />

        <div v-if="errors" class="mb-5">
          <ul>
            <li v-for="(error, key) in errors.graphQLErrors" :key="key">
              {{ error.message }}
            </li>
          </ul>
        </div>
        <div class="flex items-center">
          <span> {{ t('Imported DataPoints') }}: </span>
          <AtLoader v-if="updloadFileLoading" class="ml-2" size="sm" />
        </div>
        <table v-if="importedDataPoints.length" class="table">
          <thead>
            <tr>
              <th>Project</th>
              <th>DataPointType name</th>
              <th>DataPointType friendlyName</th>
              <th>{{ t('Timeframe') }}</th>
              <th>{{ t('Value') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(dp, key) in importedDataPoints"
              :key="key"
              data-cy="importedDataPointLi"
            >
              <td>{{ dp.location?.name }}</td>
              <td>{{ t(dp.dataPointType.name) }}</td>
              <td>{{ t(dp.dataPointType.friendlyName) }}</td>
              <td>
                <AtDataPointRequestDate :from="dp.from" :to="dp.to" />
              </td>
              <td><MlDataPointValue :data-point-value-and-type="dp" /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
