<script setup lang="ts">
import { computed, ref, watch, onMounted, watchEffect, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  DocumentDuplicateIcon,
  PencilIcon,
  LightBulbIcon,
  ShieldCheckIcon,
  CubeIcon,
  BookmarkIcon,
} from '@heroicons/vue/outline';
import { StarIcon, XIcon } from '@heroicons/vue/solid';
import { useRoute, useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { useLazyQuery, useQuery } from '@vue/apollo-composable';
import sanitize from 'sanitize-html';
import dayjs from '@/lib/dayjs/config';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import {
  UserRole,
  type SuggestionQuery,
  type OgSidePanelQuery,
  RestrictionsFeaturesEnum,
  ReportingFrameworkEnum,
  RecommendationTagEnum,
} from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import MlCommentBox from '@/components/molecules/MlCommentBox.vue';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import PreviouslyApproved from '@/components/organisms/PreviouslyApproved.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import useAddDataPointTypeOverrideMutation from '@/api/mutations/EntityLocation/addDataPointTypeOverride.mutation';
import useAddUserToRecommendationMutation from '@/api/mutations/Recommendation/addUserToRecommendation.mutation';
import useAddRatingToRecommendationMutation from '@/api/mutations/Recommendation/addRatingToRecommendation.mutation';
import useCreateDataPointRequestChatCommentMutation from '@/api/mutations/DataPointRequest/createDataPointRequestChatComment.mutation';
import useFollowDataPointRequestMutation from '@/api/mutations/DataPointRequest/followDataPointRequest.mutation';
import useUnfollowDataPointRequestMutation from '@/api/mutations/DataPointRequest/unfollowDataPointRequest.mutation';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import { getUserName } from '@/utils/helpers/getUserName';
import SUGGESTION_QUERY from '@/components/molecules/Tiptap/Suggestion.query';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import AtLoader from '@/components/atoms/AtLoader/AtLoader.vue';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import useAddDataPointTypeOverrideByEntityMutation from '@/api/mutations/EntityLocation/addDataPointTypeOverrideByEntity.mutation';
import type { TDataPointRequest } from '../../types';
import OG_SIDE_PANEL_QUERY from './OgSidePanel.query';

const { t } = useI18n();
const { currentUser, currentUserName, currentUserPictureUrl, activeEntity } =
  useCurrentUser();
const route = useRoute();
const router = useRouter();

const emit = defineEmits(['hide']);
const props = defineProps<Props>();
type Props = {
  item?: TDataPointRequest;
  isCustomQuestionnaireCategoryOrSubcategory: boolean;
};

const { result: resultTeamUsers } = useQuery<SuggestionQuery>(SUGGESTION_QUERY);
const { result, load } = useLazyQuery<OgSidePanelQuery>(OG_SIDE_PANEL_QUERY, {
  entityId: activeEntity.value?._id,
  dataPointTypeId: () => props.item?.dataPointType._id,
});

watchEffect(() => {
  if (props.item && activeEntity.value?._id) {
    load(undefined, {
      entityId: activeEntity.value?._id,
      dataPointTypeId: props.item?.dataPointType._id,
    });
  }
});

const recommendations = computed(() => result.value?.getRecommendations ?? []);
const recommendationsOpened = ref<Record<string, boolean>>({});

const teamUsers = computed(() => resultTeamUsers.value?.getTeamUsers ?? []);
const isRecommendationsTabVisible = computed(
  () =>
    !!activeEntity.value?.entitySettings.notificationSettings
      ?.recommendationsVisibility,
);

watch(() => props.item?._id, reset);

const {
  mutate: addDataPointTypeOverride,
  loading: addDataPointTypeOverrideLoading,
  error: addDataPointTypeOverrideError,
} = useAddDataPointTypeOverrideMutation();

const {
  mutate: addDataPointTypeOverrideByEntity, // apply all locations in the currentUser entity
  loading: addDataPointTypeOverrideByEntityLoading,
  error: addDataPointTypeOverrideByEntityError,
} = useAddDataPointTypeOverrideByEntityMutation();

const {
  mutate: createDataPointRequestChatComment,
  loading: createDataPointRequestChatCommentLoading,
  error: createDataPointRequestChatCommentError,
} = useCreateDataPointRequestChatCommentMutation();

const {
  mutate: addUserToRecommendation,
  loading: addUserToRecommendationLoading,
  error: addUserToRecommendationError,
} = useAddUserToRecommendationMutation();

const {
  mutate: addRatingToRecommendation,
  loading: addRatingToRecommendationLoading,
  error: addRatingToRecommendationError,
} = useAddRatingToRecommendationMutation();

const {
  mutate: followDataPointRequest,
  loading: followDataPointRequestLoading,
  error: followDataPointRequestError,
} = useFollowDataPointRequestMutation();
const {
  mutate: unfollowDataPointRequest,
  loading: unfollowDataPointRequestLoading,
  error: unfollowDataPointRequestError,
} = useUnfollowDataPointRequestMutation();

const tabs = computed(() => {
  if (
    props.item?.dataPointType.valueDataType &&
    isRecommendationsTabVisible.value
  ) {
    return [
      { value: 'sidePanelDetails', name: t('Details') },
      { value: 'sidePanelRecommendations', name: t('Recommendations') },
      { value: 'sidePanelComments', name: t('Chat') },
    ];
  }
  return [
    { value: 'sidePanelDetails', name: t('Details') },
    { value: 'sidePanelComments', name: t('Chat') },
  ];
});
const itemTab = ref(tabs.value[0]);
onMounted(() => {
  itemTab.value =
    tabs.value.find((tab) => tab.value === route.query.tab?.toString()) ??
    tabs.value[0];
});

watch(itemTab, () => {
  router.push({
    ...route,
    query: {
      ...route.query,
      tab: itemTab.value.value,
    },
  });
});
const itemOverride = computed(() => props.item?.override);
const chatCommentText = ref('');
const chatCommentTextTrimmed = computed(() =>
  chatCommentText.value.replace(/<\/?[^>]+(>|$)/g, '').trim(),
);

const guidanceCommentText = ref('');
const guidanceCommentTextTrimmed = computed(() =>
  guidanceCommentText.value.replace(/<\/?[^>]+(>|$)/g, '').trim(),
);
const isGuidanceCommentByEntity = ref(false);

const copyingToClipboard = ref(false);
const copyingRecommendationId = ref('');
const isGuidanceCommentOpen = ref(false);
const hoveredStar = ref(0);
const selectedRating = ref(0);
const guidanceCommentAuthor = computed(() => ({
  url: itemOverride.value?.guidanceComments[0]?.user.picture?.downloadUrl,
  userName: getUserName({
    firstName: itemOverride.value?.guidanceComments[0]?.user.firstName,
    lastName: itemOverride.value?.guidanceComments[0]?.user.lastName,
    email: itemOverride.value?.guidanceComments[0]?.user.email,
  }),
}));

watch(itemOverride, () => {
  guidanceCommentText.value =
    itemOverride.value?.guidanceComments[0]?.text ?? '';
});

function reset() {
  isGuidanceCommentOpen.value = false;
}

const upsertDataPointTypeOverrideByLocation = (locationId: string) =>
  addDataPointTypeOverride(
    {
      dataPointTypeOverrideInput: {
        dataPointTypeId: props.item?.dataPointType._id,
        guidanceCommentText: guidanceCommentText.value,
      },
      entityLocationInput: {
        _id: locationId,
      },
    },
    { update: (store) => store.evict({ fieldName: 'getMyDataPointRequests' }) },
  );

const upsertDataPointTypeOverrideByEntity = () =>
  addDataPointTypeOverrideByEntity(
    {
      dataPointTypeOverrideInput: {
        dataPointTypeId: props.item?.dataPointType._id,
        guidanceCommentText: guidanceCommentText.value,
      },
    },
    { update: (store) => store.evict({ fieldName: 'getMyDataPointRequests' }) },
  );

async function handleUpsertDataPointTypeOverride() {
  try {
    if (isGuidanceCommentByEntity.value) {
      await upsertDataPointTypeOverrideByEntity();
    } else {
      await upsertDataPointTypeOverrideByLocation(
        props.item?.location._id ?? '',
      );
    }
    isGuidanceCommentOpen.value = false;
  } catch (err) {
    if (addDataPointTypeOverrideError.value)
      notify({
        type: 'error',
        text: t(addDataPointTypeOverrideError.value?.message),
      });
    if (addDataPointTypeOverrideByEntityError.value)
      notify({
        type: 'error',
        text: t(addDataPointTypeOverrideByEntityError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function handleCreateDataPointRequestChatComment(
  dprId: string,
  text: string,
) {
  try {
    await createDataPointRequestChatComment({
      createDataPointRequestChatCommentInput: {
        dprId,
        text: text.replace(/(\r\n|\n|\r)/gm, '').replace(/\s+/g, ' '), // format
        link: route.fullPath,
      },
    });
    chatCommentText.value = '';
  } catch (err) {
    if (createDataPointRequestChatCommentError.value)
      notify({
        type: 'error',
        text: t(createDataPointRequestChatCommentError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

const textCopied = inject('textCopied', ref(''));

async function copyRecommendation(recommendationId: string, tags: string[]) {
  const text =
    recommendations.value.find((rec) => rec._id === recommendationId)?.text ??
    '';
  try {
    copyingRecommendationId.value = recommendationId;
    if (tags.includes(RecommendationTagEnum.TEAM_ANSWER)) {
      navigator.clipboard.writeText(text);
      textCopied.value = text;
      copyingToClipboard.value = true;
      setTimeout(() => {
        copyingToClipboard.value = false;
        copyingRecommendationId.value = '';
      }, 100);
      return;
    }
    await addUserToRecommendation(
      { recommendationId },
      { update: (store) => store.evict({ fieldName: 'getRecommendations' }) },
    );
    copyingRecommendationId.value = '';
    navigator.clipboard.writeText(text);
    textCopied.value = text;
  } catch (err) {
    if (addUserToRecommendationError.value)
      notify({
        type: 'error',
        text: t(addUserToRecommendationError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

async function rateRecommendation(rating: number, recommendationId: string) {
  if ([1, 2, 3, 4, 5].includes(rating)) {
    try {
      await addRatingToRecommendation(
        { rating, recommendationId },
        { update: (store) => store.evict({ fieldName: 'getRecommendations' }) },
      );
    } catch (err) {
      if (addRatingToRecommendationError.value)
        notify({
          type: 'error',
          text: t(addRatingToRecommendationError.value?.message),
        });
      // eslint-disable-next-line no-console
      console.error(err);
    }
  } else {
    notify({ type: 'error', text: t('Please select a valid rating.') });
  }
}

async function handleDataPointRequestFollowing(
  isFollowing: boolean,
  dataPointRequestId: string,
) {
  try {
    if (isFollowing) {
      await followDataPointRequest(
        { dataPointRequestId },
        {
          update: (store) =>
            store.evict({ fieldName: 'getMyDataPointRequests' }),
        },
      );
    } else {
      await unfollowDataPointRequest(
        { dataPointRequestId },
        {
          update: (store) =>
            store.evict({ fieldName: 'getMyDataPointRequests' }),
        },
      );
    }
  } catch (err) {
    if (followDataPointRequestError.value)
      notify({
        type: 'error',
        text: t(followDataPointRequestError.value?.message),
      });
    if (unfollowDataPointRequestError.value)
      notify({
        type: 'error',
        text: t(unfollowDataPointRequestError.value?.message),
      });
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
</script>

<template>
  <Teleport to="#sidepanel">
    <div class="bg-white">
      <div
        class="sticky top-0 flex flex-col h-screen overflow-auto px-6 pt-2.5"
      >
        <div class="flex flex-col">
          <div class="flex justify-between mb-[16px]">
            <h3 v-if="!props.item" class="py-2 text-lg font-normal leading-6">
              {{ t('No question selected') }}
            </h3>

            <h3
              v-else
              class="py-2 text-lg font-normal leading-6 truncate"
              :title="t(props.item?.dataPointType.friendlyName ?? '')"
            >
              {{ t(props.item?.dataPointType.friendlyName ?? '') }}
            </h3>
          </div>
          <XIcon
            class="absolute w-4 top-3 right-3 hover:text-primary cursor-pointer"
            :title="t('Close')"
            @click="emit('hide')"
          />

          <MlTabs v-model="itemTab" :tabs="tabs" isEdgeToEdge class="mb-4" />
          <div
            v-if="!props.item"
            class="text-gray-700 text-xs font-normal font-['Inter'] leading-tight"
          >
            <h4
              class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]"
            >
              {{ t('No information to display yet.') }}
            </h4>
          </div>
          <div
            v-else-if="itemTab.value === 'sidePanelDetails'"
            class="text-gray-700 text-xs font-normal font-['Inter'] leading-tight"
          >
            <div
              v-if="
                props.item.dataPointType.activeReportingFramework.required ||
                props.item.dataPointType.activeReportingFramework.boundary ||
                props.item.dataPointType.activeReportingFramework.phasein
              "
              class="bg-blue-50 border-blue-600 border p-2 rounded mb-4 text-gray-900"
            >
              <p
                v-if="
                  props.item.dataPointType.activeReportingFramework.required !==
                  undefined
                "
                class="flex"
              >
                <ShieldCheckIcon
                  class="w-5 float-left text-blue-600 shrink-0 mr-2 self-start"
                />

                {{ t('This question is:') }}&nbsp;
                <span class="text-blue-600 font-semibold">
                  <template
                    v-if="
                      props.item.dataPointType.activeReportingFramework.required
                    "
                  >
                    {{ t('mandatory') }}
                  </template>
                  <template v-else>
                    {{ t('voluntary') }}
                  </template>
                </span>
              </p>
              <p
                v-if="
                  props.item.dataPointType.activeReportingFramework.boundary
                "
                class="flex mt-3"
              >
                <CubeIcon
                  class="w-5 float-left text-blue-600 shrink-0 mr-2 self-start"
                />

                <span>
                  {{
                    t(
                      'Answer should reflect the following parts of the value chain (boundaries):',
                    )
                  }}
                  <span class="text-blue-600 font-semibold">
                    {{
                      t(
                        props.item.dataPointType.activeReportingFramework
                          .boundary,
                      )
                    }}
                  </span>
                </span>
              </p>
              <p
                v-if="props.item.dataPointType.activeReportingFramework.phasein"
                class="flex mt-3"
              >
                <BookmarkIcon
                  class="w-5 float-left text-blue-600 shrink-0 mr-2 self-start"
                />

                <span>
                  {{
                    t(props.item.dataPointType.activeReportingFramework.phasein)
                  }}
                </span>
              </p>
            </div>

            <div class="rounded border border-gray-200 p-2 mb-2">
              <h4
                class="text-gray-900 text-xs font-semibold font-['Inter'] leading-tight border-b border-gray-200 pb-2"
              >
                {{ t('Help') }}
              </h4>
              <div class="mt-2">
                <template
                  v-if="
                    props.item.dataPointType.reportingFrameworks.find(
                      (reportingFramework) =>
                        reportingFramework.framework ===
                        ReportingFrameworkEnum.CUSTOM,
                    )
                  "
                >
                  <MlHtmlContent
                    :html="
                      props.item.dataPointType.questionHelp.length
                        ? props.item.dataPointType.questionHelp
                        : 'Help content does not exist yet.'
                    "
                  />
                </template>
                <template v-else>
                  <pre class="whitespace-pre-wrap" style="font-family: inter"
                    >{{
                      t(
                        props.item.dataPointType.questionHelp.length
                          ? props.item.dataPointType.questionHelp.trim()
                          : 'Help content does not exist yet.',
                      )
                    }}
                  </pre>
                </template>
              </div>
            </div>
            <MlCommentBox
              v-if="!itemOverride?.guidanceComments.length"
              v-model="guidanceCommentText"
              v-rolefrom="UserRole.ADMIN"
              iconSize="sm"
              buttonContent="Add guidance"
              :placeholder="t('Enter a guidance comment.')"
              class="mb-2"
              isBlueButton
              @isCommentBoxOpen="isGuidanceCommentOpen = $event"
            >
              <template #description>
                <p
                  class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]"
                >
                  {{
                    t(
                      'Leave additional guidance or instructions that is going to be shown on this page to all team members.',
                    )
                  }}
                </p>
              </template>
            </MlCommentBox>
            <div v-else class="rounded border border-gray-200 px-2 mb-2">
              <div
                class="mt-4 mb-2 border-b border-gray-200 pb-1.5 font-semibold flex justify-between"
              >
                <p>
                  {{ t('Guidance from your admin') }}
                </p>
                <button
                  v-rolefrom="UserRole.ADMIN"
                  class="flex text-xs text-primary hover:underline gap-x-1"
                  @click="isGuidanceCommentOpen = !isGuidanceCommentOpen"
                >
                  <PencilIcon class="w-3.5" />
                  {{ t('Edit') }}
                </button>
              </div>
              <MlTextarea
                v-if="isGuidanceCommentOpen"
                v-model="guidanceCommentText"
                v-rolefrom="UserRole.ADMIN"
                class="mb-2"
                :placeholder="t('Enter a guidance comment.')"
              />
              <div v-else>
                <div class="flex justify-between mb-2 text-align-center">
                  <span class="font-medium flex items-center">
                    <AtAvatar
                      class="!h-5 !w-5 mr-2"
                      :url="guidanceCommentAuthor.url"
                      :userName="guidanceCommentAuthor.userName"
                    />
                    {{ guidanceCommentAuthor.userName }}
                  </span>

                  <span>
                    {{
                      dayjs(itemOverride?.guidanceComments[0].updatedAt).format(
                        'MMMM D, YYYY',
                      )
                    }}
                  </span>
                </div>
                <MlHtmlContent
                  class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px] mb-2"
                  :html="itemOverride?.guidanceComments[0].text"
                />
              </div>
            </div>
            <div v-if="isGuidanceCommentOpen" class="flex items-center">
              <AtCheckbox
                :checked="isGuidanceCommentByEntity"
                @toggleCheckbox="
                  isGuidanceCommentByEntity = !isGuidanceCommentByEntity
                "
              />
              <p class="text-gray-500 text-xs">
                {{ t('Apply to all projects in this workspace') }}
              </p>
            </div>
            <Transition name="vertical-fade-slide">
              <div class="flex justify-end">
                <AtButton
                  v-if="
                    guidanceCommentTextTrimmed.length && isGuidanceCommentOpen
                  "
                  v-rolefrom="UserRole.ADMIN"
                  :loading="
                    addDataPointTypeOverrideLoading ||
                    addDataPointTypeOverrideByEntityLoading
                  "
                  class="mb-3"
                  @click.stop.prevent="handleUpsertDataPointTypeOverride"
                >
                  {{ t('Submit') }}
                </AtButton>
              </div>
            </Transition>

            <div class="rounded border border-gray-200 px-2">
              <PreviouslyApproved
                :dataPointType="props.item.dataPointType"
                :location="props.item.location"
              />
            </div>
          </div>
          <div
            v-else-if="itemTab.value === 'sidePanelRecommendations'"
            class="text-gray-700 text-xs font-normal font-['Inter'] leading-tight"
          >
            <h4
              v-if="!recommendations.length"
              class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]"
            >
              <span
                v-if="
                  route.query?.category?.toString().toLowerCase() ===
                  RestrictionsFeaturesEnum.CARBON_ACCOUNTING.toLowerCase()
                "
              >
                {{
                  t(
                    'The recommendation could not be generated as this is a company-specific question that requires company-specific data upload.',
                  )
                }}
              </span>
              <span v-else>
                {{ t('No recommendation to display yet.') }}
              </span>
            </h4>
            <AtInfoBox
              v-if="
                !recommendations.length &&
                isCustomQuestionnaireCategoryOrSubcategory
              "
              class="flex py-3"
            >
              <span class="-ml-1">
                <LightBulbIcon class="w-5 text-primary" />
              </span>
              <span class="italic">
                {{
                  t(
                    'Please note that all recommendations are provided as best practice answer examples or detailed guidance, but in every case they should be reviewed before submitting.',
                  )
                }}
              </span>
            </AtInfoBox>
            <div
              v-for="recommendation in recommendations"
              :key="recommendation._id"
              class="rounded border border-gray-200 px-2 mb-2"
            >
              <div class="border-b-[1px] border-gray-200 py-2 mt-1 mb-2">
                <div class="flex justify-between">
                  <div class="flex">
                    <VTooltip
                      :triggers="['click', 'touch']"
                      autoHide
                      class="cursor-pointer shrink-0 align-baseline text-gray-400 hover:text-gray-500 flex"
                    >
                      <StarIcon
                        v-for="star in Math.round(recommendation.averageRating)"
                        :key="star"
                        class="w-5 text-amber-500"
                      />
                      <StarIcon
                        v-for="missingStar in Math.round(
                          4.999 - recommendation.averageRating,
                        )"
                        :key="missingStar"
                        class="w-5 text-gray-400"
                      />
                      <template #popper>
                        <h4
                          class="text-gray-900 text-sm font-medium font-['Inter'] leading-tight"
                        >
                          {{ t('Give rating') }}
                        </h4>
                        <div class="flex my-2">
                          <StarIcon
                            v-for="star in 5"
                            :key="star"
                            class="w-10 text-gray-400 cursor-pointer"
                            :class="{
                              '!text-amber-500':
                                star <= hoveredStar ||
                                (hoveredStar === 0 && star <= selectedRating),
                            }"
                            @mouseenter="hoveredStar = star"
                            @focusin="hoveredStar = star"
                            @mouseleave="hoveredStar = 0"
                            @focusout="hoveredStar = 0"
                            @click="selectedRating = star"
                          />
                        </div>
                        <div class="flex justify-between">
                          <AtButton
                            class=""
                            variant="outline"
                            @click="selectedRating = 0"
                          >
                            {{ t('Cancel') }}
                          </AtButton>
                          <AtButton
                            class=""
                            :loading="addRatingToRecommendationLoading"
                            @click="
                              rateRecommendation(
                                selectedRating,
                                recommendation._id,
                              )
                            "
                          >
                            {{ t('Save') }}
                          </AtButton>
                        </div>
                      </template>
                    </VTooltip>
                  </div>
                  <span class="font-normal text-gray-500 ml-auto">
                    {{ dayjs(recommendation.createdAt).format('MMMM D, YYYY') }}
                  </span>
                </div>
                <div class="flex mt-2">
                  <AtBadge
                    v-for="tag in recommendation.tags"
                    :key="tag"
                    class="mx-1"
                    :type="tag"
                  >
                    {{ t(tag) }}
                  </AtBadge>
                </div>
              </div>
              <MlHtmlContent
                :html="recommendation.text"
                class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px] mb-2 line-clamp-6 cursor-pointer"
                :class="{
                  'line-clamp-none': recommendationsOpened[recommendation._id],
                }"
                @click.stop.prevent="
                  recommendationsOpened[recommendation._id] =
                    !recommendationsOpened[recommendation._id]
                "
              />
              <AtButton
                class="mb-2"
                variant="outline"
                :loading="
                  copyingRecommendationId === recommendation._id &&
                  (addUserToRecommendationLoading || copyingToClipboard)
                "
                @click="
                  copyRecommendation(recommendation._id, recommendation.tags)
                "
              >
                <DocumentDuplicateIcon
                  v-if="!addUserToRecommendationLoading"
                  class="w-4 cursor-pointer"
                />
                {{ t('Copy') }}
              </AtButton>
            </div>
          </div>
          <div
            v-else-if="itemTab.value === 'sidePanelComments'"
            class="text-gray-700 text-xs font-normal font-['Inter'] leading-tight"
          >
            <p class="text-gray-400 mb-3 text-xs">
              {{
                t(
                  'Note that this is an internal chat and comments will not be displayed on Statistics page or any of the exports.',
                )
              }}
            </p>

            <div class="flex items-center">
              <AtCheckbox
                :checked="
                  !!props.item.followers?.find(
                    (follower) => follower._id === currentUser?._id,
                  )
                "
                @toggleCheckbox="
                  handleDataPointRequestFollowing($event, props.item._id)
                "
              />
              <span
                class="text-gray-700 text-xs font-medium font-['Inter'] leading-[18px]"
              >
                {{ t('Follow conversation') }}
              </span>
              <AtLoader
                v-if="
                  followDataPointRequestLoading ||
                  unfollowDataPointRequestLoading
                "
                size="sm"
                class="ml-2"
              />
            </div>
            <h4
              v-if="!props.item.chatComments?.length"
              class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px]"
            >
              {{ t('No comment to display yet.') }}
            </h4>
            <TransitionGroup name="vertical-fade-slide">
              <div
                v-for="chatComment in props.item.chatComments ?? []"
                :key="chatComment._id"
                class="rounded border border-gray-200 px-2 mb-2"
              >
                <div class="mt-2 pb-1.5 font-semibold flex justify-between">
                  <div class="font-medium flex items-center">
                    <AtAvatar
                      class="!h-5 !w-5 mr-2"
                      :url="chatComment.user.picture?.downloadUrl"
                      :userName="
                        getUserName({
                          firstName: chatComment.user.firstName,
                          lastName: chatComment.user.lastName,
                          email: chatComment.user.email,
                        })
                      "
                    />

                    {{
                      getUserName({
                        firstName: chatComment.user.firstName,
                        lastName: chatComment.user.lastName,
                        email: chatComment.user.email,
                      })
                    }}
                  </div>

                  <div class="font-normal text-gray-500">
                    {{ dayjs(chatComment.updatedAt).format('MMMM D, YYYY') }}
                  </div>
                </div>
                <div>
                  <MlHtmlContent
                    class="text-gray-500 text-xs font-normal font-['Inter'] leading-[18px] mb-2"
                    :html="chatComment.text"
                  />
                </div>
              </div>
            </TransitionGroup>

            <div
              class="mt-6 flex w-full justify-start items-start bg-gray-50 rounded p-2 mb-2"
            >
              <div class="font-medium flex items-center">
                <AtAvatar
                  class="!h-5 !w-5 mr-2"
                  :url="currentUserPictureUrl"
                  :userName="currentUserName"
                />
              </div>

              <MlTextarea
                v-model="chatCommentText"
                :editor="false"
                class="w-full bg-white"
                wrapperClass="w-full"
                :placeholder="t('Write a comment.')"
                :teamUsersMentionList="teamUsers"
              />
            </div>
            <div class="w-full flex justify-end mb-4">
              <Transition name="vertical-fade-slide">
                <AtButton
                  v-if="chatCommentTextTrimmed.length"
                  :loading="createDataPointRequestChatCommentLoading"
                  class=""
                  @click.stop.prevent="
                    handleCreateDataPointRequestChatComment(
                      props.item._id ?? '',
                      chatCommentText,
                    )
                  "
                >
                  {{ t('Comment') }}
                </AtButton>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
