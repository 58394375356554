<script setup lang="ts">
import { computed, inject, watch, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import MlSelectLevel3 from '@/components/molecules/MlSelectLevel3/MlSelectLevel3.vue';
import { formatDateRangeForBE } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import chartImg from '@/assets/chart.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import type { DataPoint } from '../types';
import type {
  DataReadyEvent,
  SingleStandardTableExportEvent,
} from '../PgResults.vue';
import { usePgStandardView } from './composables';
import OgStandardTable from './OgStandardTable/OgStandardTable.vue';
import { ValueDataTypeEnum } from '@/__generated__/types';
import OgQualitativeTable from '@/components/pages/PgStatistics/PgResults/PgStandardView/OgQualitativeTable/OgQualitativeTable.vue';
import MlSidebarTabs, {
  type TItem,
} from '@/components/molecules/MlSidebarTabs.vue';
import AtPageNavTeleport from '@/components/organisms/OgPageNav/AtPageNavTeleport.vue';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';

const emit = defineEmits<{
  dataReady: [
    event: {
      type: 'standard';
      data: DataReadyEvent['data'];
    },
  ];
  export: [event: SingleStandardTableExportEvent];
}>();

const router = useRouter();
const { t } = useI18n();
const translateCategory = useCategoryTranslate();

const adminMode = inject(
  'adminMode',
  computed(() => false),
);

const {
  isLoading: isLoadingData,
  filters,
  filtersData,
  noData,
  dataPointTypesData,
} = usePgStandardView();

watch(
  [
    dataPointTypesData,
    () => filters.dateRange,
    () => filters.project,
    () => filters.categories,
    () => filters.workspaces,
  ],
  ([
    newDataPointsTypes,
    newDateRangeFilter,
    newProject,
    newCategories,
    newWorkspaces,
  ]) => {
    const { dateFrom, dateTo } = formatDateRangeForBE(
      newDateRangeFilter.from,
      newDateRangeFilter.to,
    );

    emit('dataReady', {
      type: 'standard',
      data: {
        dataPointTypeIds: newDataPointsTypes.map(
          (dataPointType) => dataPointType.type._id,
        ),
        dataPointTypeNames: newDataPointsTypes.map(
          (dataPointType) => dataPointType.type.name,
        ),
        dateFrom,
        dateTo,
        project: newProject,
        categories: newCategories,
        workspaces: newWorkspaces,
      },
    });
  },
);

function handeExportStandardsAsCsv(dataPointType: DataPoint['dataPointType']) {
  emit('export', {
    dataPointTypeId: dataPointType._id,
    dataPointTypeName: dataPointType.name,
  });
}

const sidebarItems = computed<TItem[]>(() => {
  return filtersData.categoriesOptions?.value.map((category) => {
    return {
      value: category.slug,
      label: translateCategory(category.slug),
      childs: category.subcategories.map((subcategory) => {
        return {
          value: subcategory.slug,
          label: translateCategory(category.slug, subcategory.slug),
          class: subcategory.slug.startsWith('3_') ? 'ml-3' : '',
        };
      }),
    };
  });
});

const selectedSidebarCategory = ref({ value: '' });
const selectedSidebarChildCategory = ref({ value: '' });

const handleSidebarClick = (event: TItem) => {
  // we only want to select the subcategory if it was actually directly clicked
  if (selectedSidebarCategory.value.value === event.value) {
    selectedSidebarChildCategory.value.value = event.childValue ?? '';
  } else {
    // if this is the first click on a new category, remove the subcategory filter
    selectedSidebarChildCategory.value.value = '';
  }

  selectedSidebarCategory.value.value = event.value;

  filters.categories = [event.value];
  filters.subcategories = selectedSidebarChildCategory.value.value
    ? [selectedSidebarChildCategory.value.value]
    : [];
};

const resetSidebar = () => {
  selectedSidebarCategory.value.value = '';
  selectedSidebarChildCategory.value.value = '';
  filters.categories = [];
  filters.subcategories = [];
};
</script>

<template>
  <div class="grid">
    <AtPageNavTeleport>
      <MlSidebarTabs
        :items="sidebarItems"
        @update:modelValue="handleSidebarClick"
        :model-value="selectedSidebarCategory"
        :child-model-value="selectedSidebarChildCategory"
        scrollable
        class="ml-2 mt-3"
      >
        <h2
          class="mt-3 ml-4 py-2 text-lg font-medium leading-6 text-gray-900 sm:truncate flex"
        >
          {{ t('Categories') }}
        </h2>
        <div class="h-3" />

        <a
          href=""
          @click.prevent="resetSidebar"
          class="ml-4 hover:text-primary"
          :class="{
            'text-primary':
              selectedSidebarCategory.value === '' &&
              selectedSidebarChildCategory.value === '',
          }"
          >{{ t('All data') }}</a
        >
        <div class="mb-6" />
      </MlSidebarTabs>
    </AtPageNavTeleport>

    <div id="pgStandardViewControlId" class="mb-6 grid grid-cols-12 gap-2.5">
      <MlSelectLevel3
        v-if="!adminMode"
        v-model="filters.project"
        wrapperClass="col-span-2"
        size="md"
        :placeholder="t('All projects')"
        :items="filtersData.projectsOptions.value"
      />

      <MlSelect
        v-if="adminMode"
        v-model="filters.workspaces"
        wrapperClass="col-span-2"
        :title="t('Workspace')"
        :placeholder="t('All workspaces')"
        truncate
        :options="filtersData.workspaceOptions.value"
        multiple
      />

      <OgDateRangeInput
        v-model="filters.dateRange"
        class="ml-auto col-span-2 w-full"
        isFull
      />
    </div>

    <MlEmptyStateCard
      v-if="noData"
      :title="t('No data yet')"
      :description="t('Start by entering numeric data')"
      :buttonText="t('Enter data')"
      @buttonClick="router.push({ name: 'projects' })"
    >
      <img class="mb-7" :src="chartImg" alt="Graph" />
    </MlEmptyStateCard>

    <template v-for="dataPointType in dataPointTypesData">
      <OgStandardTable
        v-if="
          [
            ValueDataTypeEnum.NUMERIC,
            ValueDataTypeEnum.NUMERIC_SPLIT,
            ValueDataTypeEnum.EMISSIONS_SPLIT,
          ].includes(dataPointType.type.valueDataType)
        "
        :key="dataPointType.type._id"
        :dataPointType="dataPointType.type"
        :frameworks="dataPointType.frameworks"
        :allDataPoints="dataPointType.allDataPoints"
        :projects="dataPointType.dataPointsPerProject"
        :shouldDisableExport="isLoadingData"
        :showTotal="!filters.project"
        @export="handeExportStandardsAsCsv(dataPointType.type)"
      />
      <OgQualitativeTable
        v-else
        :key="dataPointType.type._id"
        :dataPointType="dataPointType.type"
        :frameworks="dataPointType.frameworks"
        :allDataPoints="dataPointType.allDataPoints"
        :projects="dataPointType.dataPointsPerProject"
        :shouldDisableExport="isLoadingData"
        :showTotal="!filters.project"
        @export="handeExportStandardsAsCsv(dataPointType.type)"
        :summary-data-points="dataPointType.summaryDataPoints"
      />
    </template>
  </div>
</template>
