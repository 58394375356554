import { gql } from '@apollo/client/core';

export default gql`
  query MlQuestionForm($id: String!) {
    getDataPointRequest(id: $id) {
      _id
      suggestionFormularResult {
        result
        dependencies {
          _id
          activeReportingFramework {
            _id
            category
            subcategory
            order
          }
        }
      }
      dataPointType {
        _id
        suggestionFormular
      }
    }
  }
`;
