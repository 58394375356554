import flagsmith from 'flagsmith';
import { reactive, watch } from 'vue';
import { authenticatedUser } from '@/utils/composables/useCurrentUser/useCurrentUser';

export const featureFlags = reactive({
  ai: false,
});

export const initFlagsmith = () => {
  flagsmith.init({
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT,
    onChange: () => {
      for (const featureKey of Object.keys(featureFlags) as Array<
        keyof typeof featureFlags
      >) {
        featureFlags[featureKey] = flagsmith.hasFeature(featureKey);
      }
    },
  });

  watch(authenticatedUser, (user) => {
    if (user?.email) {
      flagsmith.identify(user.email);
    }
  });
};
