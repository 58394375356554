<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { MinusIcon, PlusIcon } from '@heroicons/vue/solid';
import { PencilIcon } from '@heroicons/vue/outline';
import MlTextarea from './MlTextarea.vue';
import { Editor } from '@tiptap/vue-3';

const { t } = useI18n();

type TProps = {
  modelValue: string;
  buttonContent?: string;
  placeholder?: string;
  disabled?: boolean;
  iconSize?: 'sm' | 'md' | 'lg';
  isBlueButton?: boolean;
  hasButton?: boolean;
  isOpen?: boolean;
  isEditing?: boolean;
  isOpenOnMounted?: boolean;
};

const props = withDefaults(defineProps<TProps>(), {
  iconSize: 'md',
  buttonContent: undefined,
  placeholder: undefined,
  isOpenOnMounted: true,
  hasButton: true,
});
const emit = defineEmits(['update:modelValue', 'isCommentBoxOpen']);

const comment = useVModel(props, 'modelValue', emit);
const isCommentBoxOpen = ref(false);
watch(
  () => props.isOpen,
  () => {
    isCommentBoxOpen.value = props.isOpen;
  },
);
watch(
  () => isCommentBoxOpen.value,
  () => emit('isCommentBoxOpen', isCommentBoxOpen.value),
);

onMounted(() => {
  if (props.modelValue && props.isOpenOnMounted) {
    isCommentBoxOpen.value = true;
  }
});

const editor = ref<typeof Editor>();
defineExpose<{ editor: typeof editor }>({ editor });
</script>

<template>
  <div>
    <div
      v-if="props.hasButton"
      class="flex cursor-pointer items-center"
      :class="{ 'pointer-events-none': disabled, 'text-primary': isBlueButton }"
      @click="isCommentBoxOpen = !isCommentBoxOpen"
    >
      <PencilIcon
        v-if="props.isEditing"
        :class="{
          'text-gray-200': disabled,
          'w-4': iconSize === 'sm',
          'w-5': iconSize === 'md',
          'w-6': iconSize === 'lg',
        }"
      />
      <PlusIcon
        v-else-if="!isCommentBoxOpen"
        :class="{
          'text-gray-200': disabled,
          'w-4': iconSize === 'sm',
          'w-5': iconSize === 'md',
          'w-6': iconSize === 'lg',
        }"
      />
      <MinusIcon
        v-else
        :class="{
          'text-gray-200': disabled,
          'w-4': iconSize === 'sm',
          'w-5': iconSize === 'md',
          'w-6': iconSize === 'lg',
        }"
      />
      <span class="ml-2" :class="{ 'text-gray-200': disabled }">
        {{ props.buttonContent ? t(props.buttonContent) : t('Add comment') }}
      </span>
    </div>
    <div v-show="isCommentBoxOpen">
      <slot name="description" />
      <MlTextarea
        v-model="comment"
        :placeholder="props.placeholder ?? t('Enter a comment.')"
        :ref="(el) => (editor = el?.editor)"
      />
    </div>
  </div>
</template>
