<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import axios, { type AxiosProgressEvent } from 'axios';
import { notify } from '@kyvg/vue3-notification';
import { useI18n } from 'vue-i18n';
import { useLazyQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import useStatefulProp from '@/utils/composables/useStatefulProp';
import useAddRepositoryFileMetadata from '@/api/mutations/RepositoryFile/addFileMetadata.mutation';
import useGenerateFileUploadTokenMutation from '@/api/mutations/RepositoryFile/generateFileUploadToken.mutation';
import useGenerateFileUploadTokenExternalMutation from '@/api/mutations/RepositoryFile/generateFileUploadTokenExternal.mutation';
import type {
  GetUserRepositoryFilesQuery,
  RepositoryFile,
  GenerateFileUploadTokenMutation,
  GenerateFileUploadTokenExternalMutation,
} from '@/__generated__/types';
import type { TPartialRepositoryFile } from './types';
import { GET_USER_REPOSITORY_FILES } from './OgS3FilePicker.query';
import MlFilePicker from './MlFilePicker.vue';

type TProps = {
  modelValue?: TPartialRepositoryFile | null;
  uploading?: boolean;
  showRecentFiles?: boolean;
  dprDelegationInput?: {
    userId: string;
    entityId: string | null;
  };
  repositoryFileType?: 'proof' | 'evidence' | 'ai_context'; // TRepositoryFileType
  multiple?: boolean;
  customFileMeta?: Record<
    keyof RepositoryFile,
    RepositoryFile[keyof RepositoryFile]
  >;
};

const props = defineProps<TProps>();

const emit = defineEmits<TEmits>();
type TEmits = {
  (
    e: 'update:modelValue',
    repositoryFile?: Pick<RepositoryFile, '_id' | 'filename'> | null,
  ): void;
  (e: 'update:uploading', uploading: boolean): void;
};

const { t } = useI18n();

const uploading = useStatefulProp(props.uploading, 'update:uploading');
const route = useRoute();
const isExternalDataEntryPath = computed(
  () =>
    !!route.path.match(/^\/external-data-entry/) ||
    !!route.path.match(/^\/external-taxonomy-assessment/),
);

const { mutate: generateFileUploadToken } =
  useGenerateFileUploadTokenMutation();
const { mutate: generateFileUploadTokenExternal } =
  useGenerateFileUploadTokenExternalMutation();
const { mutate: addFileMetadata } = useAddRepositoryFileMetadata();

const {
  result: recentFilesResult,
  load: getRecentFiles,
  refetch: refetchRecentFiles,
} = useLazyQuery<GetUserRepositoryFilesQuery>(GET_USER_REPOSITORY_FILES);

onMounted(() => {
  if (!isExternalDataEntryPath.value) {
    return props.showRecentFiles && getRecentFiles();
  }
});

const recentFiles = computed(
  () => recentFilesResult.value?.getUserRepositoryFiles ?? [],
);
const recentFilesOptions = computed(() =>
  recentFiles.value.reduce<Record<string, string>>(
    (acc, { filename, _id }) => ({ ...acc, [_id]: filename }),
    {},
  ),
);

const uploadProgress = ref(0);
const uploadController = ref(new AbortController());

const onUploadProgress = ({ loaded, total }: AxiosProgressEvent) => {
  if (total) {
    uploadProgress.value = Math.round((loaded / total) * 100);
  }
};

const fileUploadHandler = async (file: File) => {
  if (!file) {
    return emit('update:modelValue', null);
  }

  uploading.value = true;

  let fileUploadToken:
    | GenerateFileUploadTokenMutation['generateFileUploadToken']
    | undefined;
  let fileUploadTokenExternal:
    | GenerateFileUploadTokenExternalMutation['generateFileUploadTokenExternal']
    | undefined;
  if (isExternalDataEntryPath.value) {
    const res = await generateFileUploadTokenExternal(props.dprDelegationInput);
    fileUploadTokenExternal = res?.data?.generateFileUploadTokenExternal;
  } else {
    const res = await generateFileUploadToken();
    fileUploadToken = res?.data?.generateFileUploadToken;
  }
  let repositoryFile = fileUploadToken;
  if (fileUploadTokenExternal) {
    repositoryFile = fileUploadTokenExternal;
  }
  if (repositoryFile) {
    try {
      const uploadResult = await axios.put(
        repositoryFile.preSignedUrl || '',
        await file.arrayBuffer(),
        {
          headers: { 'Content-Type': file.type },
          onUploadProgress,
          signal: uploadController.value.signal,
        },
      );

      if (uploadResult.status !== 200) return;

      await addFileMetadata({
        repositoryFile: {
          _id: repositoryFile._id,
          filename: file.name,
          contentType: file.type,
          filesize: file.size,
          secretUpdateToken: repositoryFile.secretUpdateToken,
          type: props.repositoryFileType ?? 'proof',
          ...props.customFileMeta,
        },
      });

      emit('update:modelValue', {
        _id: repositoryFile._id,
        filename: file.name,
      });

      if (props.showRecentFiles && !isExternalDataEntryPath.value) {
        refetchRecentFiles();
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.name !== 'CanceledError') {
        notify({ type: 'error', text: err?.message });
      } else {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    } finally {
      uploading.value = false;
      uploadProgress.value = 0;
    }
  }
};

const uploadCancelHandler = () => {
  uploadController.value.abort();
  uploadController.value = new AbortController();
};

const filePickerProxy = computed({
  get: () =>
    props.modelValue ? [new File([], props.modelValue?.filename ?? '')] : [],
  set: async (files) => {
    if (files.length === 0) emit('update:modelValue', null);
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      await fileUploadHandler(file);
    }
  },
});
</script>

<template>
  <MlFilePicker
    v-model="filePickerProxy"
    :loading="uploading"
    :progress="uploadProgress"
    v-bind="$attrs"
    :multiple="props.multiple"
    @uploadCancel="uploadCancelHandler"
  >
    <div v-if="showRecentFiles && recentFiles.length && !props.modelValue">
      <MlSelect
        wrapperClass="mt-2 w-full"
        sortedOptions
        :options="recentFilesOptions"
        :placeholder="t('Or choose from recent files')"
        @update:modelValue="
          emit('update:modelValue', {
            _id: $event,
            filename: recentFilesOptions[$event],
          })
        "
      />
    </div>
    <slot />
  </MlFilePicker>
</template>
