<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { TrashIcon } from '@heroicons/vue/outline';
import {
  type TmLibraryUploadsQuery,
  type RepositoryFile,
  UserRole,
} from '@/__generated__/types';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import OgS3FilePicker from '@/components/molecules/MlFilePicker/OgS3FilePicker.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import TM_LIBRARY_UPLOADS_QUERY from './TmLibraryUploads.query';
import useDeleteRepositoryFile from '@/api/mutations/RepositoryFile/deleteRepositoryFile.mutation';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import dayjs from '@/lib/dayjs/config';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';

const adminMode = inject(
  'adminMode',
  computed(() => false),
);

const { mutate: deleteRepositoryFile } = useDeleteRepositoryFile();

const {
  result,
  loading: queryLoading,
  refetch,
} = useQuery<TmLibraryUploadsQuery>(
  TM_LIBRARY_UPLOADS_QUERY,
  () => ({
    adminMode: adminMode.value,
  }),
  {
    fetchPolicy: 'network-only',
  },
);

const { t } = useI18n();

const { mutate: generateFileDownloadURLMutation } =
  useGenerateFileDownloadTokenMutation();

const downloadFile = async (file: Pick<RepositoryFile, '_id'>) => {
  const mutationResult = await generateFileDownloadURLMutation({
    repositoryFileId: file._id,
  });

  window.open(mutationResult?.data?.generateFileDownloadToken);
};

const repositoryFiles = computed(
  () =>
    (adminMode.value
      ? result.value?.getEntityRepositoryFilesAdmin
      : result.value?.getEntityRepositoryFiles) ?? [],
);

const headers = computed(() => [
  ...(adminMode.value
    ? [
        {
          text: t('Workspace'),
          value: 'entity',
          sortable: true,
          filterable: true,
        },
      ]
    : []),
  { text: t('Topics'), value: 'relatedTopics' },
  { text: t('Download'), value: 'download' },
  { text: t('Date'), value: 'createdAt' },
  { text: '', value: 'crud' },
]);

const items = computed(() =>
  repositoryFiles.value.map((file) => {
    return {
      ...(adminMode.value
        ? {
            entity: t(file.entity?.name ?? ''),
          }
        : {}),
      relatedTopics: file.relatedTopicSlugs?.map((s) => t(s)).join(', ') ?? '',
      fileName: file.filename,
      createdAt: dayjs(file.createdAt).format('DD.MM.YYYY HH:mm'),
      _id: file._id,
      onFileClick: () => {
        if (!file._id) return;
        return downloadFile(file);
      },
    };
  }),
);

const filteredAndSortedItems = ref(items.value);
watch(items, () => {
  filteredAndSortedItems.value = items.value;
});

const uploadedFile = ref();
const uploadTargetTopics = ref<string[]>([]);

watch(uploadedFile, () => {
  refetch();
  uploadedFile.value = null;
});

const topics = computed<Record<string, string>>(
  () =>
    result.value?.getCategories
      .flatMap((c) =>
        c.subcategories.map((s) => [s.slug, `${t(c.slug)}: ${t(s.slug)}`]),
      )
      .reduce(
        (acc, curr) => {
          acc[curr[0]] = curr[1];

          return acc;
        },
        {} as Record<string, string>,
      ) ?? {},
);

const customFileMeta = computed(() => ({
  relatedTopicSlugs: uploadTargetTopics.value,
}));

const handleDeleteFile = async (repositoryFileId: string) => {
  await deleteRepositoryFile({ repositoryFileId });
  await refetch();
};
</script>

<template>
  <div>
    <h2 class="font-bold text-xl mt-5 mb-3">{{ t('Upload') }}</h2>

    <p class="text-sm font-normal text-gray-500 spacing-xl">
      {{
        t(
          'This is the space ready for upload of all documents that can help our AI prepare the answers to your questions. Examples of useful upload documents are: Codes of conduct (for suppliers, customers or internal), previous sustainability reports, CDP/GRI disclosures, ISO certification documents, DNK disclosures, energy permits, any HR system exports, interviews on the sustainability agenda, presentations on the sustainability topics, UNGP commitments, and anything else that relates to the topic of environmental, social or governance practices in your organization.',
        )
      }}
    </p>

    <div class="my-12 flex flex-row gap-12">
      <OgS3FilePicker
        v-model="uploadedFile"
        :showRecentFiles="false"
        repositoryFileType="ai_context"
        :multiple="true"
        :custom-file-meta="customFileMeta"
      />

      <div class="w-1/2">
        <p class="text-sm font-normal text-gray-600">
          {{ t('The current upload should be used for the following topic:') }}
        </p>

        <MlSelect
          :options="topics"
          :sorted-options="true"
          :multiple="true"
          v-model="uploadTargetTopics"
          wrapperClass="w-1/2 mt-5"
        />
      </div>
    </div>

    <template v-if="!queryLoading">
      <OgDataTable
        :headers="headers"
        controlsHidden
        variant="dark-gray"
        wrapperClass="!pt-0"
        :items="filteredAndSortedItems"
      >
        <template #item-download="{ fileName, onFileClick }">
          <AtButton variant="link" data-cy="dataPointFile" @click="onFileClick">
            {{ fileName }}
          </AtButton>
        </template>
        <template #item-crud="row">
          <span
            v-if="true"
            class="grid grid-cols-1 place-items-center gap-x-2 justify-self-end"
          >
            <AtIconButton
              v-rolefrom="UserRole.ADMIN"
              :icon="TrashIcon"
              class="w-7 text-gray-400"
              :title="t('Delete')"
              @click.stop="handleDeleteFile(row._id)"
            />
          </span>
        </template>
      </OgDataTable>
    </template>
  </div>
</template>
