<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core';
import { v4 } from 'uuid';
import type { HTMLAttributes } from 'vue';
import { useI18n } from 'vue-i18n';
import { EditorContent, Extension, useEditor } from '@tiptap/vue-3';
import { StarterKit, type StarterKitOptions } from '@tiptap/starter-kit';
import { watch, computed, ref } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import Placeholder, {
  type PlaceholderOptions,
} from '@tiptap/extension-placeholder';
import Mention, { type MentionOptions } from '@tiptap/extension-mention';
import unwrapVuelidateErrorMessages from '@/utils/helpers/unwrapVuelidateErrorMessages';
import MenueBar from '@/components/molecules/Tiptap/MenueBar.vue';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import type { TDataPointRequest } from '@/components/pages/PgProjects/types';
import type {
  PgExternalDataEntryQuery,
  SuggestionQuery,
} from '@/__generated__/types';
import { getUserName } from '@/utils/helpers/getUserName';
import suggestion from './Tiptap/suggestion';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

interface TProps {
  label?: string;
  wrapperClass?: HTMLAttributes['class'];
  labelClass?: string;
  modelValue?: string | null;
  initialContent?: string;
  previousDatapoint?: { value: string; from: string; to: string };
  disabled?: boolean;
  editor?: boolean;
  placeholder?: string;
  teamUsersMentionList?: SuggestionQuery['getTeamUsers'][number][];
  dataPointRequest?:
    | TDataPointRequest
    | PgExternalDataEntryQuery['getDelegatedDataPointRequests'][number];
  hideMenueBar?: boolean;
  errors?: string[] | ErrorObject[];
}

const props = withDefaults(defineProps<TProps>(), {
  label: '',
  wrapperClass: '',
  labelClass: '',
  modelValue: '',
  previousDatapoint: () => ({
    value: '',
    from: '',
    to: '',
  }),
  disabled: false,
  editor: true,
  initialContent: '',
  dataPointRequest: undefined,
  placeholder: undefined,
  teamUsersMentionList: undefined,
  hideMenueBar: false,
  errors: () => [],
});

const emit = defineEmits(['update:modelValue', 'blur']);

const { t } = useI18n();

const uniqueId = v4();
const editorExtensions = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const baseExtensions: (
    | Extension<StarterKitOptions, any>
    | Extension<PlaceholderOptions, any>
    | Extension<MentionOptions, any>
  )[] = [StarterKit, Placeholder.configure({ placeholder: props.placeholder })];
  if (!props.teamUsersMentionList) return baseExtensions;
  return [
    ...baseExtensions,
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion,
      renderLabel({ options, node }) {
        return `
          ${options.suggestion.char}
          ${node.attrs.label ?? getUserName(props.teamUsersMentionList?.find((user) => user._id === node.attrs.id))}
        `;
      },
    }),
  ];
});

const editor = useEditor({
  content: props.modelValue ?? '',
  editable: !props.disabled,
  extensions: editorExtensions.value,
  editorProps: {
    attributes: {
      class: 'min-h-[8rem] outline-none',
    },
  },
  onCreate(_props) {
    if (props.teamUsersMentionList)
      _props.editor.extensionStorage.teamUsersMentionList =
        props.teamUsersMentionList;
  },
  onUpdate: (_props) => {
    emit('update:modelValue', editor.value!.getHTML());
    if (props.teamUsersMentionList)
      _props.editor.extensionStorage.teamUsersMentionList =
        props.teamUsersMentionList;
  },
  onBlur: ({ event }) => {
    emit('blur', event);
  },
});

watch(props, () => {
  if (props.modelValue === '' || props.modelValue === null) {
    editor.value?.chain().setContent('').run();
  }
});

watch(
  () => props.initialContent,
  () => {
    if (
      props.initialContent.length &&
      props.initialContent !== editor.value?.getHTML()
    ) {
      editor.value
        ?.chain()
        .setContent(props.initialContent, true, { preserveWhitespace: true })
        .run();
    }
  },
);

const copyPrevious = () => {
  editor.value?.chain().setContent(props.previousDatapoint.value).run();
};

defineOptions({ inheritAttrs: false });
defineExpose({ editor });

const loadingAi = ref(false);
const hasAi = computed(() => !!props.dataPointRequest);

const generateAi = async (operation: string) => {
  loadingAi.value = true;

  const queryVariables = {
    operation,
    dataPointRequestId: props.dataPointRequest?._id ?? '',
    context: editor.value?.getText() ?? '',
  };

  const response = await fetch(
    `${import.meta.env.VITE_API_URL.replace('/graphql', '')}/api/v1/ai/ai-text-enhancement`,
    {
      method: 'POST',
      body: JSON.stringify(queryVariables),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('apiKey')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok || !response.body) {
    console.error(response);
  } else {
    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    let result = '';
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      result += decoder.decode(value, { stream: true });

      editor.value?.commands.setContent(result, true);
    }
  }

  loadingAi.value = false;
};

const showAiExplanation = ref(false);
</script>

<template>
  <div :class="props.wrapperClass">
    <div v-if="props.previousDatapoint?.value">
      <a class="cursor-pointer text-xs" @click.stop="copyPrevious">
        <i18n-t
          keypath="Click {here} to fill the value from the last datapoint (provided in {year})"
        >
          <template #here>
            <span class="cursor-pointer text-primary underline">
              {{ t('here') }}
            </span>
          </template>
          <template #year>
            <AtDataPointRequestDate
              :from="props.previousDatapoint.from"
              :to="props.previousDatapoint.to"
            />
          </template>
        </i18n-t>
      </a>
    </div>
    <label
      :for="uniqueId"
      class="block mb-1 text-sm font-medium text-gray-700"
      :class="props.labelClass"
    >
      {{ t(props.label) }}
    </label>

    <MenueBar
      v-if="editor && props.editor && !props.hideMenueBar"
      class="editor__header !border border-gray-400 px-1"
      :editor="editor"
    />

    <div class="">
      <editor-content
        v-if="editor"
        :id="uniqueId"
        :editor="editor"
        class="block border-b-0 rounded-b-none border border-gray-400 p-1 shadow-sm outline-none sm:text-sm"
        :class="{
          'opacity-50': props.disabled,
          'border-t-0': props.editor && !props.hideMenueBar,
          'rounded-t-md': !props.editor,
          'border-rose-600 bg-rose-50': props.errors.length,
        }"
        data-cy="MlTexareaTextarea"
        v-bind="$attrs"
      />

      <div
        v-if="hasAi"
        class="flex flex-row-reverse gap-5 bg-white border-x border-x-gray-400 p-3"
      >
        <AtButton
          variant="outline"
          @click.prevent="() => generateAi('shorten')"
          :loading="loadingAi"
          :disabled="loadingAi"
          >{{ t('Shorten answer') }}</AtButton
        >
        <AtButton
          variant="outline"
          @click.prevent="() => generateAi('optimize')"
          :loading="loadingAi"
          :disabled="loadingAi"
          >{{ t('Optimize answer') }}</AtButton
        >
        <AtButton
          variant="outline"
          @click.prevent="() => generateAi('suggest')"
          :loading="loadingAi"
          :disabled="loadingAi"
          >{{ t('Generate suggestion') }}</AtButton
        >
      </div>
    </div>

    <div
      v-if="hasAi"
      @click="showAiExplanation = !showAiExplanation"
      class="bg-gray-50 text-center text-gray-500 font-light py-1 border border-gray-400 border-t-0 text-sm"
    >
      <i18n-t keypath="Codio Impact AI makes mistakes sometimes. {Hereiswhy}.">
        <template #Hereiswhy>
          <span class="text-primary cursor-pointer">
            {{ t("Here's why") }}
          </span>
        </template>
      </i18n-t>

      <p v-if="showAiExplanation" class="text-left p-3">
        {{
          t(
            'AI makes mistakes because it relies on pattern recognition, not true understanding. Errors come from training data limitations, ambiguity, overgeneralization, and lack of real-world experience. It can also use outdated information or misinterpret complex questions. Since AI doesn’t "think" like humans, it sometimes applies the wrong patterns or misses context.',
          )
        }}
      </p>
    </div>

    <p
      v-for="error in unwrapVuelidateErrorMessages(props.errors)"
      :key="error"
      class="mt-1 text-xs text-error"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ t(error) }}
      </span>
    </p>
  </div>
</template>

<style lang="postcss">
.ProseMirror ul,
.ProseMirror ol {
  margin-left: 25px;
}

.ProseMirror ul {
  @apply list-disc;
}

.ProseMirror ol {
  @apply list-decimal;
}

.ProseMirror h1 {
  @apply text-xl font-normal leading-7 text-gray-900;
}

.ProseMirror h2 {
  @apply text-sm font-medium text-gray-900;
}

.mention {
  color: #2563eb;
  font-size: 0.8rem;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>
