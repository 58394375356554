import { gql } from '@apollo/client/core';

export default gql`
  query TmLibraryUploads($adminMode: Boolean!) {
    getEntityRepositoryFiles(type: "ai_context") @skip(if: $adminMode) {
      _id
      ...tmLibraryUploadsFields
    }

    getEntityRepositoryFilesAdmin(type: "ai_context") @include(if: $adminMode) {
      _id
      ...tmLibraryUploadsFields
    }

    getCategories {
      _id
      slug

      subcategories {
        _id
        slug
      }
    }
  }

  fragment tmLibraryUploadsFields on RepositoryFile {
    _id
    filename
    createdAt

    relatedTopicSlugs

    entity {
      _id
      name
    }
  }
`;
